<template>
  <div>
    <div class="app-wrapper">
      <div class="container py-5">
        <div class="col-lg-10 mx-auto p-0 app-wrapper__content">
          <div class="empty-links text-center py-5 col-lg-6 mx-auto">
            <h3 class="ana_h3 ana_text-dark py-2">
              Get detailed insight into your usage across our platform
            </h3>
            <p class="ana_paragraph ana_text-grey pb-5">
              This will be available soon
            </p>
            <img
              src="@/assets/img/app/usage.svg"
              class="d-block mx-auto my-4"
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ActivityTimeline from "@/components/dash/Analytics/ActivityTimeline";
export default {
  name: "Usage",
  metaInfo: {
    title: "Usage Analytics - Ana app",
  },
  components: {
    ActivityTimeline,
  },
};
</script>

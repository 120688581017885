<template>
  <div ref="chartContainer" class="chart-container"></div>
</template>

<script>
import { createChart } from "lightweight-charts";

export default {
  name: "ActivityLineChart",
  props: ["chartData"],
  mounted() {
    this.renderChart();
  },
  methods: {
    renderChart() {
      const chartData = this.chartData;

      const chartContainer = this.$refs.chartContainer;
      const chartOptions = {
        layout: {
          textColor: "white",
          background: { type: "solid", color: "black" },
        },
      };

      const chart = createChart(chartContainer, chartOptions);

      const lineSeries = chart.addLineSeries({ color: "#2962FF" });
      lineSeries.setData(chartData);

      chart.timeScale().fitContent();
    },
  },
};
</script>

<style>
.chart-container {
  height: 200px;
}
</style>
